import * as Sentry from "@sentry/react";
import React from "react";

import { getPool, isDevelopment } from "./util/pool";

import Amplify from "aws-amplify";
import App from "./components/App";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import pools from "./pools";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root"));

Sentry.init({
  environment: isDevelopment() ? "development" : "production",
  dsn: "https://54abd1a140e54b578a0e486b0c174b95@o1081075.ingest.sentry.io/6088192",
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: ["https://dev.talentdriver.co/api/", "/api/"]
    })
  ],
  tracesSampleRate: isDevelopment() ? 1.0 : 0.2
});

const pool = getPool(pools, window.location.hostname);

if (pool) {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: isDevelopment()
            ? "https://dev.talentdriver.co/api/"
            : "/api/"
        }
      ]
    }
  });
}

if (!pool) {
  window.location = "https://talentdriver.co/";
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
